import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['drawer'];

    connect() {
    }

    initialize() {
        this.tabIndexOff();
    }

    tabIndexOff() {
        this.drawerTarget.querySelectorAll('a').forEach(link => {
            link.setAttribute("tabindex", "-1");
        });
        
        this.drawerTarget.querySelectorAll('button').forEach(link => {
            link.setAttribute("tabindex", "-1");
        });
    }

    tabIndexOn() {
        this.drawerTarget.querySelectorAll('a').forEach(link => {
            link.setAttribute("tabindex", "0"); 
        });
        
        this.drawerTarget.querySelectorAll('button').forEach(link => {
            link.setAttribute("tabindex", "0"); 
        });
    }

    toggle() {
        console.log("toggle");
        
        if (this.element.classList.contains("is-active")) {
            this.close();
            this.tabIndexOff();
        } else {
            this.open();
            this.tabIndexOn();
            this.drawerTarget.focus();
        }
    }

    open() {
        console.log("open");
        this.element.classList.add("is-active");
    }

    close() {
        console.log("close");
        this.element.classList.remove("is-active");
    }
}
