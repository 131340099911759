import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['form', 'radio', 'input'];

    connect() {
        this.radioTargets.forEach(radio => {
            radio.addEventListener('change', (event) => {
                this.setAction(event);
            });
        });
    
        if (window.location.href.includes('for-sale')) {
            this.radioTargets.forEach(radio => {
                if (radio.value === 'for-sale') {
                    radio.checked = true;
                }
            });
        } else {
            this.radioTargets.forEach(radio => {
                if (radio.value === 'for-rent') {
                    radio.checked = true;
                }
            });
        }

        // Set initial form action
        this.setAction();
    }

    setAction(event = false) {
        const baseUrl = '/properties/';
        let value = '';
        if (event) {
            value = event.target.value;
        } else {
            value = this.radioTargets.find(radio => radio.checked).value;
        }
        const newUrl = baseUrl + value;
        const queryParams = window.location.search;

        console.log('Generated New URL:', newUrl + queryParams);

        this.formTarget.action = newUrl + queryParams;
    }

    // setAction(event = false) {
    //     console.log('Setting action...');
    //     const baseUrl = '/properties/';
    //     let value = '';
    //     if (event) {
    //         value = event.target.value;
    //     } else {
    //         value = this.radioTargets.find(radio => radio.checked).value;
    //     }
        
    //     const newUrl = new URL(baseUrl + value, window.location.origin);
    //     const currentFormActionURL = this.formTarget.action;
    //     const currentParams = new URLSearchParams(currentFormActionURL.split('?')[1]);

    //     console.log('Current URL:', currentFormActionURL);
    //     console.log('Current Params:', currentParams.toString());
    
    //     // Get the search value from the input field
        
    //     if (this.inputTarget && this.inputTarget.value) {
    //         currentParams.set('s', this.inputTarget.value); // 's' is the parameter name for the search value
    //     }
    
    //     // Append existing query parameters to the new URL
    //     newUrl.search = currentParams.toString();
    
    //     console.log('Generated New URL:', newUrl.toString());
    
    //     this.formTarget.action = newUrl.toString();
    //     console.log('...Action set!');
    // }
}