import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["item"];

    connect() {
        this.itemTargets.forEach((item) => {
            item.addEventListener('click', () => this.toggleSelected(item))
        })
    }

    toggleSelected(item) {
        item.classList.toggle('selected')
    }
}
