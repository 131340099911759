import { Controller } from "@hotwired/stimulus";
import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade } from 'swiper/modules';

export default class extends Controller {
    static targets = ['slide', 'count', 'pagination']; //, 'next', 'prev'];

    connect() {
        const carousel = new Swiper(this.element, {
            modules: [Pagination, EffectFade],
            slidesPerView: 1,
            spaceBetween: 96,
            grabCursor: true,
            loop: true,
            autoHeight: true,
            // navigation: {
            //     nextEl: this.nextTarget,
            //     prevEl: this.prevTarget,
            // },
            pagination: {
                el: this.paginationTarget,
                clickable: true,
            },
            on: {
                slideChange: (swiper) => {
                    this.countTarget.innerHTML = swiper.realIndex + 1;
                },
            },
        });
    }
}
