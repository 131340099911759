import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["map", "marker"];

  connect() {
    document.addEventListener('google-maps-loaded', this.initMaps.bind(this));
  }

  disconnect() {
    document.removeEventListener('google-maps-loaded', this.initMaps.bind(this));
  }

  initMaps() {
    this.mapTargets.forEach((mapElement) => {
      this.initMap(mapElement);
    });
  }

  initMap(el) {
    // Find marker elements within map.
    const markers = el.querySelectorAll(".marker");

    // Create generic map.
    const mapArgs = {
      zoom: el.dataset.zoom || 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,

    };
    const map = new google.maps.Map(el, mapArgs);

    // Add markers.
    map.markers = [];
    markers.forEach((markerElement) => {
      this.initMarker(markerElement, map);
    });

    // Center map based on markers.
    this.centerMap(map);

    // Return map instance.
    return map;
  }

  initMarker(markerElement, map) {
    // Get position from marker.
    const lat = markerElement.dataset.lat;
    const lng = markerElement.dataset.lng;
    const latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    // Create marker instance.
    const marker = new google.maps.Marker({
      position: latLng,
      map: map,
    });

    // Append to reference for later use.
    map.markers.push(marker);

    // If marker contains HTML, add it to an infoWindow.
    if (markerElement.innerHTML) {
      // Create info window.
      const infowindow = new google.maps.InfoWindow({
        content: markerElement.innerHTML,
      });

      // Show info window when marker is clicked.
      marker.addListener("click", function () {
        infowindow.open(map, marker);
      });
    }
  }

  centerMap(map) {
    // Create map boundaries from all map markers.
    const bounds = new google.maps.LatLngBounds();
    map.markers.forEach((marker) => {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng(),
      });
    });

    // Case: Single marker.
    if (map.markers.length == 1) {
      map.setCenter(bounds.getCenter());
      // Case: Multiple markers.
    } else {
      map.fitBounds(bounds);
    }
  }
}