import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default class extends Controller {
  static targets = ["header"];

  connect() {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '#mainContent',
        start: "top+=160",
        end: "+=150",
        toggleActions: "play none none reverse",
        onEnter: () => {
          tl.timeScale(1.0);
        },
        onEnterBack: () => {
          tl.timeScale(5.0);
        }
      },
    });

    tl.to(this.element, {
      translateY: "-100%",
      onComplete: () => {
        this.element.classList.add("is-fixed");
      },
      onReverseComplete: () => {
        this.element.classList.remove("is-fixed");
      },
    });

    tl.to(this.element, {
      position: "fixed",
      top: 0,
    });

    tl.to(this.element, {
      translateY: "0%",
      duration: 0.25,
    });
  }
}
