import { Controller } from "@hotwired/stimulus"

import Plyr from 'plyr'

export default class extends Controller {
    static targets = ["player"]

    static values = {
        autoplay: String,
        width: String,
        height: String,
        hash: String
    }

    connect() {
        const player = this.initPlayer()

        if (this.hashValue) {
            document.addEventListener('play-video-modal-' + this.hashValue, () => {
                player.play();
            });

            document.addEventListener('pause-video-modal-' + this.hashValue, () => {
                player.pause();
            });
        }
    }

    initPlayer() {
        const is_hero = this.element.classList.contains('hero__media-video')
        const autoplay = this.autoplayValue == 'true' ? true : false
        const controls = autoplay ? [] : ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'fullscreen']
        const ratio = this.widthValue && this.heightValue ? this.widthValue + ':' + this.heightValue : false

        const player = new Plyr(this.playerTarget, {
            ratio: ratio,
            controls: controls,
            autoplay: autoplay,
            loop: { active: autoplay },
            volume: autoplay ? 0 : 1,
            muted: autoplay,
            playsinline: autoplay
        })

        console.log(player, autoplay, player.muted)

        return player
    }
}
