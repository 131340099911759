import { Controller } from "@hotwired/stimulus";
import { delay } from "lodash";
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

export default class extends Controller {
    static targets = ['slide', 'pagination', 'next', 'prev'];
    static values = {
        loop: Boolean,
        delay: Number
    }

    connect() {
        console.log('Testimonials controller connected');
        // console.log(this.loopValue);
        const testimonials = new Swiper(this.element, {
            modules: [Autoplay, Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 96,
            loop: true,
            autoplay: this.loopValue ? {
                delay: this.delayValue * 1000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            } : false,
            autoHeight: true,
            watchOverflow: true,
            navigation: {
                nextEl: this.nextTarget,
                prevEl: this.prevTarget,
            },
            pagination: {
                el: this.paginationTarget,
                clickable: false,
            }            
        });
    }
}
