import { Controller } from "@hotwired/stimulus";
// import * as Turbo from "@hotwired/turbo";
// import Lenis from '@studio-freight/lenis'

export default class extends Controller {
    static targets = ['wrapper', 'form', 'checkbox', 'input'];

    connect() {
        let formTop = this.formTarget.getBoundingClientRect().top
        const headerHeight = document.querySelector('.header').offsetHeight
        const offset = 32
        formTop = formTop - headerHeight - offset

        this.inputTargets.forEach((input) => {
            input.addEventListener('change', () => {
                sessionStorage.setItem('scrollPosition', formTop);
                this.formTarget.submit()
            })
        })
    }

    submitForm() {
        this.formTarget.submit()
    }
}
